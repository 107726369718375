import { Container, Typography, FormControl, Button } from '@mui/material';

import LogoutIcon from '@mui/icons-material/Logout';

import { Header } from '../styled-components';

const DashboardHeader = ({ name, logout }) => {
  return (
    <Header>
      <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" component="span">
          Hello, {name}!
        </Typography>

        <FormControl>
          <Button variant="text" endIcon={<LogoutIcon />} sx={{ color: '#fff' }} onClick={logout}>
            Logout
          </Button>
        </FormControl>
      </Container>
    </Header>
  );
};

export default DashboardHeader;
