import { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import InputMask from 'react-input-mask';

import { Box, Grid, FormControl, Rating, TextField, Button } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { required_fields, validateEmail } from '../utils';
import { Wrapper, Heading, Container } from '../styled-components';
import Loader from '../components/Loader';

const MAIL_URL = 'https://admin.wefixappliancerepair.com/wp-json/contact-form-7/v1/contact-forms/226/feedback';

const mediaEndpoint = 'https://admin.wefixappliancerepair.com/wp-json/wp/v2/media';

const postEndpoint = 'https://admin.wefixappliancerepair.com/wp-json/wp/v2/claims';

export const Logo = () => (
  <Box sx={{ maxWidth: 200, margin: '0 auto 24px' }}>
    <img src="https://wefix-appliance.com/wp-content/uploads/Logo-1.png" alt="Company Logo" />
  </Box>
);

const Home = () => {
  const [isPending, setRequestStatus] = useState(false);
  const [token, setToken] = useState();
  const [review, setReview] = useState({
    rating: null,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    comment: '',
    photo: '',
    image: null,
    isEdited: false,
  });
  const [errors, setErrors] = useState({});

  // Action functions
  const onChangeImage = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setReview((prevState) => ({
          ...prevState,
          photo: reader.result,
        }));
      });
      reader.readAsDataURL(event.target.files[0]);

      setReview((prevState) => ({
        ...prevState,
        image: event.target.files[0],
      }));
    }
  };

  const removePhoto = () =>
    setReview((prevState) => ({
      ...prevState,
      photo: '',
      image: null,
    }));

  const validation = () => {
    required_fields.forEach((field) => {
      if (review[field] === '') {
        setErrors((prevState) => ({
          ...prevState,
          [field]: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          [field]: false,
        }));
      }
    });

    if (review.email !== '' && !validateEmail(review.email)) {
      toast.error('Invalid email format!');
      setErrors((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (review.phoneNumber !== '' && review.phoneNumber.length !== 10) {
      toast.error('Invalid phone format!');
      setErrors((prevState) => ({
        ...prevState,
        phoneNumber: true,
      }));
    }
  };

  // Handler functions
  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setReview((prevState) => ({
      ...prevState,
      [key]: key === 'phoneNumber' ? val.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1$2$3') : val,
      isEdited: true,
    }));
  };

  // Async functions
  const getToken = async () => {
    try {
      const response = await axios.post('https://admin.wefixappliancerepair.com/wp-json/jwt-auth/v1/token', {
        username: 'dev',
        password: 'kzJm ep2V D6I5 M8Rb 521J qN4O',
      });

      if (response.status === 200) {
        setToken(response.data.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createPost = async () => {
    const post = {
      title: `${review.firstName} ${review.lastName}`,
      status: 'publish',
      acf: {
        rating: review.rating,
        first_name: review.firstName,
        last_name: review.lastName,
        email: review.email,
        phone: review.phoneNumber,
        comment: review.comment,
      },
    };

    const postHeaders = new Headers();
    postHeaders.append('Authorization', `Bearer ${token}`);
    postHeaders.append('Content-Type', 'application/json');

    const mediaHeaders = new Headers();
    mediaHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('file', review.image);

    const requestOptionsMedia = {
      method: 'POST',
      headers: mediaHeaders,
      body: formData,
    };

    fetch(mediaEndpoint, requestOptionsMedia)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const postBody = JSON.stringify({ ...post, featured_media: data.id });

          const requestOptions = {
            method: 'POST',
            headers: postHeaders,
            body: postBody,
            redirect: 'follow',
          };

          fetch(postEndpoint, requestOptions)
            .then((response) => response.json())
            .catch((error) => console.log('error', error));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const submit = async () => {
    if (!review.rating) return toast.error('Rating required');

    delete review.photo;
    delete review.isEdited;

    const formData = new FormData();

    Object.entries(review).map((el) => formData.append(el[0], el[1]));

    try {
      setRequestStatus(true);
      const response = await axios.post(MAIL_URL, formData);
      if (response.status === 200) {
        toast.success('Success');
        createPost();
        setReview({
          rating: null,
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          comment: '',
          photo: '',
          image: null,
          isEdited: false,
        });
        setErrors({});
        setRequestStatus(false);
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      setRequestStatus(false);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    if (review.isEdited) !Object.values(errors).includes(true) && submit();
  }, [errors]);

  return (
    <Wrapper>
      <Container>
        <Logo />

        <Heading>Business Review</Heading>

        {isPending && <Loader />}

        {!isPending && (
          <Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
              <Rating
                value={review.rating}
                onChange={(event, newValue) => {
                  event.preventDefault();

                  setReview((prevState) => ({
                    ...prevState,
                    rating: newValue,
                  }));
                }}
                sx={{
                  fontSize: 36,
                }}
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="Fist Name"
                  value={review.firstName}
                  fullWidth
                  name="firstName"
                  onChange={handleChange}
                  error={errors.firstName}
                  helperText={errors.firstName && 'This field is required'}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  label="Last Name"
                  value={review.lastName}
                  fullWidth
                  name="lastName"
                  onChange={handleChange}
                  error={errors.lastName}
                  helperText={errors.lastName && 'This field is required'}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  required
                  type="email"
                  label="Email"
                  value={review.email}
                  fullWidth
                  name="email"
                  onChange={handleChange}
                  error={errors.email}
                  helperText={errors.email && 'This field is required'}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <InputMask mask="(999) 999-9999" value={review.phoneNumber} maskChar=" " onChange={handleChange}>
                  {() => (
                    <TextField
                      required
                      type="tel"
                      name="phoneNumber"
                      label="Phone"
                      fullWidth
                      error={errors.phoneNumber}
                      helperText={errors.phoneNumber && 'This field is required'}
                    />
                  )}
                </InputMask>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  label="Comment"
                  multiline
                  rows={4}
                  value={review.comment}
                  fullWidth
                  name="comment"
                  onChange={handleChange}
                  error={errors.comment}
                  helperText={errors.comment && 'This field is required'}
                />
              </Grid>

              {review.photo && (
                <Grid item xs={12}>
                  <Box>
                    <img src={review.photo} alt="review" />
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                {!review.photo && (
                  <FormControl fullWidth>
                    <Button variant="text" component="label" endIcon={<CloudUploadIcon />} sx={{ color: '#070707' }}>
                      Upload Photo
                      <input hidden accept="image/*, .HEIC" type="file" name="photo" onChange={onChangeImage} />
                    </Button>
                  </FormControl>
                )}

                {review.photo && (
                  <Button variant="text" onClick={removePhoto} fullWidth sx={{ color: '#070707' }}>
                    Remove Photo
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={validation}
                  fullWidth
                  sx={{
                    background: '#ffd400',
                    color: '#070707',
                    '&:hover': {
                      background: '#43b5a0',
                      color: '#fff',
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Container>

      <ToastContainer />
    </Wrapper>
  );
};

export default Home;
