import { useState, useEffect, Fragment } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';
import moment from 'moment';
import CountUp from 'react-countup';
import { useCookies } from 'react-cookie';

import {
  Box,
  Grid,
  Rating,
  Container,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Link,
} from '@mui/material';

import DashboardHeader from '../components/DashboardHeader';

import sarasota_reviews from '../reviews/sarasota.json';
import seminole_reviews from '../reviews/seminole.json';
import orlando_reviews from '../reviews/orlando.json';
import miami_reviews from '../reviews/miami.json';

import { CounterItem } from '../styled-components';
import { useAuth } from '../auth/useAuth';

const PIPEDRIVE_URL = 'https://wefix-appliance.pipedrive.com/api/v1';

const TOKEN = '746ff251cdd8f207f383328deb13a2ce7a747c31';

const sarasota = sarasota_reviews.map((el) => ({
  name: el.author_title,
  rating: el.review_rating,
  date: el.review_datetime_utc.split(' ')[0],
  comment: el.review_text,
  location: '681',
}));

const seminole = seminole_reviews.map((el) => ({
  name: el.author_title,
  rating: el.review_rating,
  date: el.review_datetime_utc.split(' ')[0],
  comment: el.review_text,
  location: '682',
}));

const miami = miami_reviews.map((el) => ({
  name: el.author_title,
  rating: el.review_rating,
  date: el.review_datetime_utc.split(' ')[0],
  comment: el.review_text,
  location: '683',
}));

const orlando = orlando_reviews.map((el) => ({
  name: el.author_title,
  rating: el.review_rating,
  date: el.review_datetime_utc.split(' ')[0],
  comment: el.review_text,
  location: '684',
}));

const calculateRating = (data) => {
  const sum = data.reduce((partialSum, el) => {
    return partialSum + Number(el.rating);
  }, 0);

  return Number(sum / data.length).toFixed(2);
};

const defineReviews = (data) => {
  const arr = data?.map((review) => ({
    rating: Number(review.title.split('|')[1]) || 0,
    name: review.title.split('by ')[1].split(' | ')[0],
    date: moment(review.add_time).format('MM/DD/YYYY'),
    comment: review['e58af4276656d5f257fe7fd36da551dbca0a3731'],
    location: review['8400fec5978b9f853234404c9be3db2b2a6458bf'] || 'claims',
  }));

  return arr;
};

const defineSource = (id) => {
  let source;

  switch (id) {
    case '681':
      source = {
        title: 'Sarasota GMB',
        link: 'https://www.google.com/maps/place/WeFix-Appliance/@27.2686567,-82.4648737,17z/data=!3m1!4b1!4m6!3m5!1s0x88c347fc6e3ddb07:0x1341b460642fe2e1!8m2!3d27.2686567!4d-82.462685!16s%2Fg%2F11pd5hvqm_',
      };
      break;
    case '682':
      source = {
        title: 'Seminole GMB',
        link: 'https://www.google.com/maps/place/WeFix-Appliance/@27.8481856,-82.8314469,17z/data=!3m1!4b1!4m6!3m5!1s0x88c2f9a27ff4bf85:0x881a5fe7afb0723a!8m2!3d27.8481856!4d-82.8292582!16s%2Fg%2F11r9qt5584',
      };
      break;
    case '683':
      source = {
        title: 'Miami GMB',
        link: 'https://www.google.com/maps/place/WeFix-Appliance/@25.9426946,-80.1498662,17z/data=!3m2!4b1!5s0x88d9ac571dd244b7:0x558bd1cf55eefed7!4m6!3m5!1s0x88d9ad0011752e19:0xb124d0c11da1cfa4!8m2!3d25.9426946!4d-80.1476775!16s%2Fg%2F11pzbkg3dr',
      };
      break;
    case '684':
      source = {
        title: 'Orlando GMB',
        link: 'https://www.google.com/maps/place/WeFix-Appliance/@28.5578237,-81.3793782,17z/data=!3m2!4b1!5s0x88e77af3951f105b:0xa88e54ce664a71d8!4m6!3m5!1s0x88e77b62f8a30a99:0x32e38473f5064261!8m2!3d28.5578237!4d-81.3771895!16s%2Fg%2F11s21dw72s',
      };
      break;
    default:
      source = { title: 'Claims', link: null };
      break;
  }

  return source;
};

const Dashboard = () => {
  const { isLoading, data, error } = useFetch(
    `${PIPEDRIVE_URL}/pipelines/5/deals:(id,status,title,add_time,e58af4276656d5f257fe7fd36da551dbca0a3731,9ee6c2b59c25f7d7b1aa3c89b73ba9dce405d33e,8400fec5978b9f853234404c9be3db2b2a6458bf,207ec2f64dd56842b52f53f8a1496985cf60944f)?api_token=${TOKEN}`,
  );

  const [cookies] = useCookies();
  const { logout } = useAuth();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [reviews, setReviews] = useState([...miami, ...sarasota, ...seminole, ...orlando]);
  const [reviewsToShow, setReviewsToShow] = useState([]);
  const [activeTab, setActiveTab] = useState('total');

  // Action functions
  const sortBySource = (id) => {
    if (id) {
      if (id !== 'total') {
        const sorted = reviews.filter((el) => el.location === id);
        setReviewsToShow(sorted);
      } else {
        setReviewsToShow(reviews);
      }

      switch (id) {
        case 'total':
          setActiveTab('total');
          break;
        case 'claims':
          setActiveTab('claims');
          break;
        case '683':
          setActiveTab('miami');
          break;
        case '681':
          setActiveTab('sarasota');
          break;
        case '682':
          setActiveTab('seminole');
          break;
        case '684':
          setActiveTab('orlando');
          break;
        default:
          setActiveTab('total');
          break;
      }
    } else setReviewsToShow(defineReviews(data?.data));
  };

  // Handler functions
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Hooks
  useEffect(() => {
    if (data) setReviews((prevState) => [...prevState, ...defineReviews(data?.data)]);
  }, [data]);

  useEffect(() => {
    setReviewsToShow(reviews);
  }, [reviews]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading || !reviews)
    return (
      <Box sx={{ textAlign: 'center' }}>
        <CircularProgress />

        <Typography variant="body2" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Box>
    );

  return (
    <Fragment>
      <DashboardHeader name={cookies.name} logout={logout} />

      <Container component="main">
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} onClick={() => sortBySource('total')}>
            <CounterItem style={{ background: activeTab === 'total' && '#eee' }}>
              <Typography variant="h5">Total</Typography>
              <Typography variant="h6">
                <CountUp duration={1} end={reviews.length} />
              </Typography>

              <Typography variant="body2">Rating: {calculateRating(reviews)}</Typography>
            </CounterItem>
          </Grid>
          <Grid item xs={12} md={2} onClick={() => sortBySource('claims')}>
            <CounterItem style={{ background: activeTab === 'claims' && '#eee' }}>
              <Typography variant="h5">Claims</Typography>
              <Typography variant="h6">
                <CountUp duration={1} end={reviews?.filter((el) => el.location === 'claims').length} />
              </Typography>

              <Typography variant="body2">
                Rating: {calculateRating(reviews?.filter((el) => el.location === 'claims'))}
              </Typography>
            </CounterItem>
          </Grid>

          <Grid item xs={12} md={2} onClick={() => sortBySource('683')}>
            <CounterItem style={{ background: activeTab === 'miami' && '#eee' }}>
              <Typography variant="h5">Miami</Typography>
              <Typography variant="body2">33160</Typography>
              <Typography variant="h6">
                <CountUp duration={1} end={reviews?.filter((el) => el.location === '683').length} />
              </Typography>

              <Typography variant="body2">
                Rating: {calculateRating(reviews?.filter((el) => el.location === '683'))}
              </Typography>
            </CounterItem>
          </Grid>

          <Grid item xs={12} md={2} onClick={() => sortBySource('681')}>
            <CounterItem style={{ background: activeTab === 'sarasota' && '#eee' }}>
              <Typography variant="h5">Sarasota</Typography>
              <Typography variant="body2">34233</Typography>
              <Typography variant="h6">
                <CountUp duration={1} end={reviews?.filter((el) => el.location === '681').length} />
              </Typography>

              <Typography variant="body2">
                Rating: {calculateRating(reviews?.filter((el) => el.location === '681'))}
              </Typography>
            </CounterItem>
          </Grid>

          <Grid item xs={12} md={2} onClick={() => sortBySource('682')}>
            <CounterItem style={{ background: activeTab === 'seminole' && '#eee' }}>
              <Typography variant="h5">Seminole</Typography>
              <Typography variant="body2">33776</Typography>
              <Typography variant="h6">
                <CountUp duration={1} end={reviews?.filter((el) => el.location === '682').length} />
              </Typography>

              <Typography variant="body2">
                Rating: {calculateRating(reviews?.filter((el) => el.location === '682'))}
              </Typography>
            </CounterItem>
          </Grid>

          <Grid item xs={12} md={2} onClick={() => sortBySource('684')}>
            <CounterItem style={{ background: activeTab === 'orlando' && '#eee' }}>
              <Typography variant="h5">Orlando</Typography>
              <Typography variant="body2">32803</Typography>
              <Typography variant="h6">
                <CountUp duration={1} end={reviews?.filter((el) => el.location === '684').length} />
              </Typography>

              <Typography variant="body2">
                Rating: {calculateRating(reviews?.filter((el) => el.location === '684'))}
              </Typography>
            </CounterItem>
          </Grid>
        </Grid>

        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Rating</TableCell>
                <TableCell width="15%" sx={{ fontWeight: 'bold' }}>
                  Full Name
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Source</TableCell>
                <TableCell width="40%" sx={{ fontWeight: 'bold' }}>
                  Review
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviewsToShow
                .sort(function (a, b) {
                  if (moment(a.date) > moment(b.date)) return -1;
                  return 1;
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((review, index) => (
                  <TableRow key={index} hover>
                    <TableCell scope="row">{review.date}</TableCell>
                    <TableCell>
                      <Rating value={Number(review.rating)} readOnly />
                    </TableCell>
                    <TableCell>{review.name}</TableCell>
                    <TableCell>
                      {defineSource(review.location).link ? (
                        <Link href={defineSource(review.location).link} target="_blank">
                          {defineSource(review.location).title}
                        </Link>
                      ) : (
                        defineSource(review.location).title
                      )}
                    </TableCell>
                    <TableCell>{review.comment}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={reviewsToShow.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Reviews per page"
        />
      </Container>
    </Fragment>
  );
};

export default Dashboard;
