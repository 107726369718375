import styled from '@emotion/styled';

import { mq } from './utils';

const Wrapper = styled('section')`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

const Heading = styled('h1')`
  font-weight: bold;
  font-size: 28px;
  line-height: 1.24;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 24px;
`;

const Header = styled('header')(() => ({
  padding: '15px 0',
  margin: '0 0 15px',
  background: '#43b5a0',
  color: '#fff',
}));

const Container = styled('div')(() => ({
  maxWidth: 600,
  margin: '0 auto',

  [mq(768)]: {
    maxWidth: '100%',
    padding: '0 15px 45px',
  },
}));

const CounterItem = styled('div')`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  min-height: 130px;
  padding: 30px 0 0;

  border: 1px solid #ffd400;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &:hover {
    background-color: #eee;
  }
`;

export { Wrapper, Heading, Container, Header, CounterItem };
