import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Global } from '@emotion/react';
import AppProvider from './auth/useProvider';

import 'react-toastify/dist/ReactToastify.css';

import { base_styles } from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AppProvider>
      <Global styles={base_styles} />
      <App />
    </AppProvider>
  </BrowserRouter>,
);
