import { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import api from './api';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  const login = async ({ username, password }) => {
    const response = await api.post('/jwt-auth/v1/token', {
      username: username,
      password: password,
    });

    setCookies('token', response.data.token);
    setCookies('name', response.data.user_display_name);

    navigate('/dashboard');
  };

  const logout = () => {
    ['token', 'name'].forEach((obj) => removeCookie(obj));
    navigate('/login');
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
