import { Box, CircularProgress, Typography } from '@mui/material';

const Loader = () => (
  <Box sx={{ textAlign: 'center' }}>
    <CircularProgress />

    <Typography variant="body2" sx={{ mt: 2 }}>
      Sending...
    </Typography>
  </Box>
);

export default Loader;
