import { Routes, Route, Navigate } from 'react-router-dom';

import { ProtectRoutes } from './auth/protectRoutes';

import Home from './pages/Home';
import Thanks from './pages/Thanks';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import LoginPage from './pages/LoginPage';

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />

    <Route path="/login" element={<LoginPage />} />

    <Route path="/thank-you" element={<Thanks />} />

    <Route path="/404" element={<NotFound />} />

    <Route element={<ProtectRoutes />}>
      <Route path="/dashboard" element={<Dashboard />} />
    </Route>

    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);

export default App;
